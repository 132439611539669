import React from "react";
import FormWrapper from "components/FormWrapper";

const DataMigration = () => {
  return (
    <FormWrapper className="bg-white">
      <h1 className="h2 text-center">Data Migration In Progress</h1>
      <p className="mb-6">
        Data is currently being migrated from the legacy Data Portal and
        Coalition Builder into the new system. The app is unavailable while this
        takes place, please check back soon.
      </p>
    </FormWrapper>
  );
};

export default DataMigration;
